import React from 'react'
import { Ipassicon } from '../../../assets/icons/Icons'
import './passCard.scss'
import { getUserData } from '../../../functions/common'
import useCurrentDateTime from '../../../functions/dateTime';

function Passigcard() {
    const data = getUserData();
    return (
        <div>
            <div className='box_sizing'>
                <p className='text_first'>
                    {useCurrentDateTime()}
                    {/* {data?.full_name} */}
                </p>
                <div className='card-wrapper'>
                <p className='id_text'>iPASS</p>
                <p className='heading_text'>
                   {data?.pass_number}
                </p>
                </div>
                {/* <div style={{ marginTop: "50px" }}>
                    <Ipassicon />
                </div> */}
            </div>
        </div>
    )
}

export default Passigcard