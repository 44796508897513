import './App.scss';
import React, { useState, useEffect } from 'react';
import AppRouter from './router/router';
import AuthProvider from './context';
import { RootContainer } from './layouts';
import { Encoding } from './functions/base64';
import InternetConnection from './components/noConnection/noConnection';

function App() {
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('VPUAuthorization');
    const passId = urlParams.get('pass_id');
    if (token) {
      setAuthToken(token);
      const authString = token?.split('_MR__');
      const access_token = authString[0];
      const refresh_token = authString[1];
      localStorage.setItem('access_token', Encoding(access_token));
      localStorage.setItem('refresh_token', Encoding(refresh_token));
    }
    if (passId) {
      window.location.href = `/pass-details?passId=${passId}`;
    }
  }, []);

  return (
    <InternetConnection>
    <AuthProvider value={{ authToken }}>
      <RootContainer>
        <AppRouter />
      </RootContainer>
    </AuthProvider>
    </InternetConnection>
  );
}

export default App;
