import React, { useEffect, useState } from 'react'
import './ipass.scss'
import { getUserData } from '../../../functions/common'
import ApiRoutes from '../../../utils/apiRoutes/routes';
import { Encoding } from '../../../functions/base64';
import { Get } from '../../../utils/services/request';
import useCurrentDateTime from '../../../functions/dateTime';
function Ipasscard() {

    const [data, setData] = useState([]);

    
    useEffect(()=>{
        getCardDetails()
    },[])

    const getCardDetails = async() => {
        const user = getUserData()
        if(user){
            setData(user)
        }else{
            try {
                const response = await Get(ApiRoutes.subscriptionListing);
                if (response.status) {
                    const res = response?.data?.data
                    if(res?.user){
                        const userData = res.user
                        localStorage.setItem('userData', Encoding(JSON.stringify(userData)));
                        setData(userData)
                    }
                }
            } catch (error) {
                return {
                    status: false,  
                    message: error.message
                }
            }
        }
    }

    return (
        <div>
            <div className='box__siznig'>
                {/* <p className='pass__text'>{data?.full_name}</p> */}
                {useCurrentDateTime()}
                <p className='id_text'>iPASS</p>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center', marginBottom: "-10px"  }}>
                    <div>
                        <p className='text_style'>
                            {data?.pass_number}
                        </p>
                    </div>
                    {/* <div>
                        <a  className='link_style'>What is Ipass ID?</a>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Ipasscard