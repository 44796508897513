import React from 'react';
import './BottomModal.scss';
import { Col } from 'antd';
import { breakdowns } from '../../../../utils/constants';
import { CloseOutlinedIcon } from '../../../../assets/icons/Icons';

const BottomModal = ({ isOpen, onClose }) => {
    return (
        <div className={`bottom__modal__rule ${isOpen ? 'open' : ''}`}>
            <Col {...breakdowns}>
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    <CloseOutlinedIcon />
                </button>
                <h4 className='modal__text'>Rules of Use</h4>
                <h4 className='modal__text_defination'>Definitions</h4>

                <div><strong>iPASS:</strong> <span>A membership program offering exclusive deals, discounts, and benefits.</span></div>
                <div><strong>iPASS Digital Membership Card:</strong> <span>A virtual card granting access to iPASS offers and benefits.</span></div>
                <div><strong>Merchant:</strong> <span>A business or service provider participating in the iPASS program.</span></div>
                <div><strong>Participating Outlet:</strong> <span>Specific locations where iPASS offers can be redeemed.</span></div>
                <div><strong>Offer:</strong> <span>A special deal, discount, or benefit available exclusively to iPASS members.</span></div>

                <br />
                <h4 className='modal__text'>General Terms</h4>
                <p>By using any iPASS offers or benefits ("Offers"), you agree to comply with these Rules of Use ("Rules") and any specific terms associated with individual Offers. Please read these Rules carefully before redeeming any Offer.</p>

                <br />
                <h4 className='modal__text'>Offer Validity</h4>

                <ul className='list__style'>
                    <li>
                         Offers are valid only for the time period specified on the Offer unless otherwise stated.
                    </li>
                    <li>
                         Offers are redeemable exclusively at the issuing merchant or venue.
                    </li>
                    <li>
                         Offers apply solely to the items, services, and locations specified.
                    </li>
                    <li>
                         Offers cannot be combined with other promotions, discounts, or loyalty programs unless explicitly stated.
                    </li>
                    <li>
                         Offers do not cover additional charges such as service fees or taxes.
                    </li>
                    <li>
                         Offers are valid seven days a week unless otherwise specified, with minimal exclusion dates.
                    </li>
                    <li>
                         Unauthorized reproduction, resale, or modification of Offers is strictly prohibited. Any Offer found to be resold or bartered will be considered void.
                    </li>

                </ul>

                <br />
                <h4 className='modal__text'>Redeeming Your Offers</h4>

                <ul className='list__style'>
                    <li>
                         To redeem an Offer, present your iPASS digital membership card to the outlet staff before your bill is issued.
                    </li>
                    <li>
                         The merchant will enter your iPASS membership number into their system to apply the Offer.
                    </li>
                    <li>
                         For multiple redemptions, repeat this process.                    </li>
                    <li>
                         In the event of any dispute, the bill must be settled according to local laws, and relevant parties will be contacted to resolve the issue.                    
                    </li>
                </ul>


                <br />
                <h4 className='modal__text'>Dining Offers</h4>

                <ul className='list__style'>
                    <li>
                         Offers are not applicable for delivery or takeaway services unless explicitly mentioned on the Offer.
                    </li>
                    <li>
                         Discounts do not cover service charges.
                    </li>
                    <li>
                         Advance booking may be required.         
                    </li>
                </ul>



                <br />
                <h4 className='modal__text'>Amusement Parks, Attractions & Leisure Offers</h4>

                <ul className='list__style'>
                    <li>
                         Terms and conditions may vary by activity. Please refer to the individual merchant for details on age restrictions, safety guidelines, and other conditions.
                    </li>
                    <li>
                         Prior booking may be required for certain activities or offers.
                    </li>
                </ul>

                <br />
                <h4 className='modal__text'>Beauty & Spas, Health & Fitness, and Retail Services Offers</h4>

                <ul className='list__style'>
                    <li>
                         Present your iPASS digital card and membership number at the time of booking, prior to service completion, or when requesting the bill.
                    </li>
                    <li>
                         For health and fitness Offers, ensure the card is presented before beginning any class or activity.
                    </li>
                    <li>
                         Prior booking may be required for specific services or activities.
                    </li>
                </ul>

                <br />
                <h4 className='modal__text'>Special Offers</h4>

                <ul className='list__style'>
                    <li>
                         Special or complimentary Offers may have different validity periods and may cover items or services separate from the main Offers.
                    </li>
                    <li>
                         The validity of Special Offers is marked on the Offer, but iPASS reserves the right to remove these Offers at any time. We are not liable if a Special Offer is discontinued before its expiration.
                    </li>
                    <li>
                         Special Offers are also subject to these Terms of Use.
                    </li>
                </ul>

                <br />
                <h4 className='modal__text'>New Offers</h4>

                <ul className='list__style'>
                    <li>
                         iPASS may introduce new Offers throughout the year from new merchants. These new Offers will be made available to all iPASS members, and the general Terms of Use shall apply.
                    </li>
                </ul>


                <br />
                <h4 className='modal__text'>Exclusion Days</h4>

                <ul className='list__style'>
                    <li>
                         Offers can be redeemed seven days a week, except on the following exclusion days in Qatar, as well as any public holidays announced by the Qatari government:</li>
                         <ul className='ml-12'>
                            <li>Christmas Eve</li>
                            <li>Christmas Day</li>
                            <li>New Year’s Eve</li>
                            <li>New Year’s Day</li>
                            <li>Eid Al Fitr</li>
                            <li>Eid Al Adha</li>
                            <li>Valentine’s Day</li>
                            <li>Qatar National Day</li>
                            <li>National Sports Day</li>
                        </ul>
                    <li>
                        Please note that some outlets may be closed during Ramadan, summer, and other religious holidays. iPASS is not responsible for any outlet that may be temporarily or permanently closed during the Offer's validity period.
                    </li>
                </ul>

            </div>
            </Col>
        </div>
    );
};

export default BottomModal;
