import React, { useState, useEffect } from 'react';
import { Col, Spin } from 'antd';
import { Filter, Header, NormalBtn, OutletCard, Newmodal, Bottomlogo } from '../../components';
import { breakdowns } from '../../utils/constants';
import { useLocation } from 'react-router-dom';
import './style.scss';
import { fetchOutletsByCategory } from '../../utils/services/apiFunctions/outlets';
import { userIsLoggedIn } from '../../functions/common';

function Index() {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  const { categoryList, categoryId } = location.state || {};
  const modifiedCategoryList = categoryList;
  const [selectedCategoryId, setSelectedCategoryId] = useState(categoryId);

  const isLoggedIn = localStorage.getItem('access_token') ? true : false;

  // Define the function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Define the function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getOutlets = async (id) => {
    setLoading(true)
    const response = await fetchOutletsByCategory(id);
    setData(response?.data ?? []);
    setLoading(false)
  }

  useEffect(() => {
    getOutlets(categoryId)
  }, [])

  const changeCategoryFilter = (id) => {
    setSelectedCategoryId(id);
    getOutlets(id)
  }

  return (
    <div>
      <div style={{paddingLeft: "15px", paddingTop: "15px"}}>
      <Header changeCategoryFilter={changeCategoryFilter} tagsList={modifiedCategoryList} selectedCategoryId={selectedCategoryId} tags type={"back"} />
      </div>
    <div className='pass__screen__main content-center'>
      {loading ? <div className="flex justify-center items-center w-full h-screen">
        <Spin size='medium' />
      </div> : <>
        {/* <Filter/> */}
        {
          data?.map((v, i) => {
            return <OutletCard data={v} key={i} km={"0.8km"} />
          })
        }
        {!isLoggedIn && <Col
          {...breakdowns}
          className='fixed bottom-3 w-full d-flex justify-content-center' >
          {/* Pass the openModal function as onClick prop */}
          <NormalBtn fixedBtn label={"PURCHASE THIS PASS"} onClick={openModal} />
        </Col>}

        {/* Render the Newmodal component with isOpen and onClose props */}
        <Newmodal isOpen={isModalOpen} onClose={closeModal} />
      </>}
 
    </div>
               {userIsLoggedIn() && <div className='postion_box'>
                <button className='bottom_logo'>
                    <Bottomlogo />
                </button>
            </div>}
    </div>
  );
}

export default Index;
