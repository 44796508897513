import React, { useRef, useState } from 'react'
import iPassLogo from '../../assets/images/svg/iPassLogo.svg'
import './style.scss'
import { BackIcon, CallIcon, DetailsIcon, MenuIcon } from '../../assets/icons/Icons'
import { OutlinedBtn, SearchInput } from '..'
import { useNavigate } from 'react-router-dom'

function Index({ type, withLogo, withInput, tags, tagsList, selectedCategoryId, changeCategoryFilter,placeholder }) {

    const navigate = useNavigate();

    const scrollRef = useRef(null);
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!startX) return;
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = x - startX;
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setStartX(null);
    };

    const handleBackClick = () => {
        navigate(-1)
    };

    const scrollToElement = (index) => {
        if (scrollRef.current) {
            const element = scrollRef?.current?.children?.[index];
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
            }
        }
    };

    return (
        <div className={`header__main__container ${type === "detail_call" ? "no__bg" : ""}`}>
            <div className='header__section'>
                {withLogo ? <div className='logo__box'>
                    <img src={iPassLogo} />
                </div>
                    : ""}
                {/* {type === "menu" ?
                    <div className='sub__section'>
                        <button className='p-0'>
                            <MenuIcon />
                        </button>
                        <SearchInput />
                    </div>
                    : ""} */}
                {type === "detail_call" ?
                    <div className='detail__Call__icon__menu'>
                        <div>
                            <button onClick={handleBackClick} >
                                <BackIcon />
                            </button>
                        </div>
                        {/* <div className='icon_section'>
                            <div>
                                <button>
                                    <DetailsIcon />
                                </button>
                            </div>
                            <div>
                                <button>
                                    <CallIcon />
                                </button>
                            </div>
                        </div> */}
                    </div>
                    : ""}
                {type === "back" ? <div className='sub__section'>
                    <button onClick={handleBackClick} className='p-0'>
                        <BackIcon />
                    </button>
                    {withInput ? <SearchInput placeholder={placeholder} /> : ""}
                    {tags ? <div
                        ref={scrollRef}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                        className='w-full tag__container'>
                        {tagsList?.map((v, i) => {
                            return <OutlinedBtn scrollToElement={scrollToElement} index={i} classes="mr-4" title={v} selectedCategoryId={selectedCategoryId} onClick={changeCategoryFilter} />
                        })}
                    </div> : ""}
                </div> : ""}


            </div>
        </div>
    )
}

export default Index
