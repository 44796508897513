import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FitnessCard, Bottomlogo, Header } from '../../components';
import Ipasscard from '../../components/cards/Ipasscard';
import './fitness.scss';
import { userIsLoggedIn } from '../../functions/common';

function Index() {
    const navigate = useNavigate(); // Correct hook for navigation
    const location = useLocation();

    const { data } = location?.state?.data || {};
    return (
        <div className='fitness__main content-center pt-0'>
            <div className='fitness__contain'>
                <Header withLogo type="menu" />
                <Ipasscard />
                <div className='pt-5 pb-2'>
                    <p className='text__style'>My Passes</p>
                </div>

                {
                    data?.map((el) => {
                        return (
                            <button className='card_btn' onClick={() => {
                                navigate('/pass-details', { state: { externalId: el?.passes[0]?.external_id } });

                            }}>
                                <FitnessCard data={el} />
                            </button>

                        )
                    })
                }
                {userIsLoggedIn() && <div className='postion_box'>
                    <button className='bottom_logo'>
                        <Bottomlogo />
                    </button>
                </div>}
            </div>
        </div>
    );
}

export default Index;
