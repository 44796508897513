import React, { useEffect, useState } from 'react'
import { Header, NormalBtn, BottomModal, Dropdown } from '../../components'
import './detail.scss'
import { breakdowns } from '../../utils/constants';
import { Col, Spin } from 'antd';
import { fetchBenefitDetailsById } from '../../utils/services/apiFunctions/outlets';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import { userIsLoggedIn } from '../../functions/common';


function Details() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  const [detailsOpen, setDetailsOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);

  const isLoggedIn = localStorage.getItem('access_token') ? true : false;

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const getBenefitDetails = async () => {
    setIsLoading(true)
    const response = await fetchBenefitDetailsById(params?.id);
    setData(response?.data ?? {})
    setIsLoading(false)
  }

  useEffect(() => {
    getBenefitDetails()
  }, [])

  const goToNext = () => {
    navigate("/passCard")
  }

  return (
    <>
      {
        isLoading ? <div className="flex justify-center items-center w-full h-screen">
          <Spin size='medium' />
        </div> :
          <div className='content-center'>
            <div className='harder__setting'>
              <div>
                <Header type='back' />
              </div>
              <div>
                <p className='header_heding' >
                  Details
                </p>
              </div>
            </div>
            <div className='main_box'>
              <p className='text_opacity'>
                {location?.state?.passName.toUpperCase()}
              </p>
              <p className='heading_text'>{data?.name}</p>
              <p className='text_style_desc'>
                {data?.short_description ?? data?.description}
              </p>
            </div>
            <div>
            </div>
            <div className='accordian__list__item'>
              <button className='flex w-full justify-between items-center' onClick={() => setDetailsOpen(!detailsOpen)}>
                <div>
                  <p className='text__style heading'>
                    Details
                  </p>
                </div>
                <div className={detailsOpen ? "rotate-180" : ""}>
                  <IoIosArrowDown />
                </div>
              </button>
              {detailsOpen ? <div>
                <p className='text__style'>
                  {data?.description}
                </p>
              </div> : ""}
            </div>
            <Col
              {...breakdowns}
              style={{ padding: "0 10px 0 0" }}
              className='fixed bottom-3 w-full d-flex justify-content-center' >
              <div className='and_text_box'>
                <p className='en_text'>This offer is subjected to <button onClick={openModal}>Rules of Use</button></p>
                <NormalBtn disabled={!userIsLoggedIn()} label="REDEEM NOW" onClick={goToNext} />

              </div>
            </Col>
            <BottomModal isOpen={isOpen} onClose={closeModal} />

          </div>
      }
    </>
  )
}

export default Details