import React from "react";
import { LocationIcon } from "../../../assets/icons/Icons";
import './style.scss'
import { useNavigate } from "react-router-dom";


const Index = ({ km, data }) => {
    const { name, address, benefits } = data;

    const navigate = useNavigate();
    const onPassClick = () => {
        navigate(`/details/${data?.external_id}`)
    }

    // const benefitsTexts = benefits?.map((v)=> v?.name)?.join(" • ")
    
    const benefitsTexts = benefits?.length > 2 
  ? `${benefits.slice(0, 2).map(v => v?.name).join(" • ")} and more` 
  : benefits?.map(v => v?.name)?.join(" • ");

    
    return <button onClick={onPassClick} className="outlet__card__main text-left">
        <img src={data?.medias?.[0]?.path} />
        <div className="mt-3">
            <h2>
                {name}
            </h2>
            <p className="pb-2">
                {benefitsTexts}
            </p>
            {/* <div className="flex justify-between location__section">
                <div className="flex gap-2 align-top mb-8">
                    <div>
                        <LocationIcon />
                    </div>
                    <p>
                        {address}
                    </p>
                </div>
                <div>
                    <p>
                        {km}
                    </p>
                </div>
            </div> */}
        </div>
    </button>
}

export default Index;