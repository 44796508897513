import React from 'react'
import './passcard.scss'
import { Header, Passigcard } from '../../components'
import config from '../../config'

export default function PassCard() {

    return (
        <div className='flex_setting content-center'>
        <div>
          <Header type="back" />
        </div>
        <div className='passCardWrapper'>
          <Passigcard />
        </div>
        <div className='passCardText'>
          <p className='cardText'>
          Present your Pass ID to the staff to redeem benefit.
          </p>
        </div>
        {/* <div className="version-container">
          <p className='version-text'>Version {config.appVersion}</p>
        </div> */}
          <div className="version-container">
        <p className='version-text'>Web Version: {config.appVersion}</p>
        </div>
      </div>
    )
}
